export const USER_ERRORS = {
  email_username: {
    invalid: 'The Username or E-mail are in an invalid format',
    required: 'The Username or E-mail field is required',
  },
  email: {
    invalid: 'The email is in an invalid format',
    required: 'Email is a required field',
  },
  username: {
    required: 'Username is a required field',
    invalid: 'Only English letters and numbers are allowed in your username. It must be at least 3 characters long',
  },
  newEmail: {
    required: 'New email address is a required field',
    invalid: 'The new email address is in an invalid format',
  },
  confirmEmail: {
    required: 'The email confirmation address is a required field',
    invalid: 'The email confirmation address is in an invalid format',
    notMatch: 'The email confirmation address does not match the new email address',
  },
  newPassword: {
    required: 'New password is a required field',
    invalid: 'The new password must be at least 4 characters long',
  },
  currentPassword: {
    required: 'Current password is a required field',
    invalid: 'The current password must be at least 4 characters long',
  },
  confirmPassword: {
    required: 'Password confirmation is a required field',
    invalid: 'The password confirmation must be at least 4 characters long',
    notMatch: 'Password confirmation does not match the new password'
  },
  password: {
    required: 'Password is a required field',
    invalid: 'The password must be at least 4 characters long',
  },
}
